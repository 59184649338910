<template>
  <div
    ref="compRef"
    :class="[
      'banner',
      'pointer',
      align,
      bannerLayout,
      `banner-height--${banner_height || 'medium'}`,
      {
        video: isVideo,
        'use-banner-overlay': show_image_overlay,
        'splash-banner': isSplashPageStyle,
        'short-height-style': !hasBackground || short_height,
        'secondary-banner-alt-banner': isSecondaryBanner && secondary_banner_alt_style,
      },
    ]"
    :style="componentStyle"
    @click="handleClick"
  >
    <Video
      v-if="isVideo"
      :preload="true"
      :poster="videoPoster"
      :asset="props.background?.[0]"
      :options="imageOptions"
      :display_as_gif="true"
    />
    <div v-if="isSecondaryBanner && secondary_banner_alt_style" class="secondary-banner-alt flex-inline">
      <BannerContent :countdown-date="countdown_date" :buttons="buttons" :content="content" :align="align" />
      <CloudinaryImage
        v-if="secondaryBannerAltImg"
        :public-id="secondaryBannerAltImg"
        :options="{ width: 200, height: 200 }"
        :image-classes-override="['secondar-banner-alt-img']"
      />
    </div>
    <div v-else :class="['container', align]" :style="bannerTextColorStyle">
      <CloudinaryImage
        v-if="inset_logo?.[0]?.public_id"
        :public-id="inset_logo?.[0]?.public_id"
        :options="{ width: 150, height: 150 }"
        :image-classes-override="['inset-logo']"
      />
      <BannerContent :countdown-date="countdown_date" :buttons="buttons" :content="content" :align="align" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ButtonCS, AssetCloudinary, ContentLayoutCS } from '~/types/contentstack';
import { useCloudinary } from '~/composables/cloudinary';
import { checkAttentive as attentive } from '~/util/attentive';
import { extractInternalLink } from '~/util/contentstack/csHelpers';
import { useDeviceStore } from '~/store/device';

const localePath = useLocalePath();
const route = useRoute();
const router = useRouter();
const deviceStore = useDeviceStore();
const cloudinary = useCloudinary();

const props = defineProps({
  background: {
    type: Array as () => AssetCloudinary[],
    required: true,
  },
  /* eslint-disable vue/prop-name-casing */
  mobile_background: {
    type: Array as () => AssetCloudinary[],
    required: false,
    default: () => [],
  },
  show_image_overlay: {
    type: Boolean,
    default: true,
  },
  secondary_banner_alt_style: {
    type: Boolean,
    default: false,
  },
  short_height: {
    type: Boolean,
    default: false,
  },
  banner_height: {
    type: String as () => 'auto' | 'short' | 'medium' | 'tall',
    default: 'medium',
  },
  content_layout: {
    type: Object as () => ContentLayoutCS,
    required: true,
  },
  inset_logo: {
    type: [Array, Object],
    required: false,
    default: () => [],
  },
  countdown_date: {
    type: String,
    default: undefined,
  },
  // eslint-disable-next-line vue/require-default-prop
  background_color: {
    type: Object as () => {
      color: string;
    },
    required: false,
  },
  /* eslint-enable vue/prop-name-casing */
  content: {
    type: String,
    required: true,
  },
  buttons: {
    type: Array as () => ButtonCS[],
    required: false,
    default: null,
  },
  isSecondaryBanner: {
    type: Boolean,
    default: false,
  },
  isPrimaryBanner: {
    type: Boolean,
    default: false,
  },
});

const compRef = ref(null);

const backgroundImage = computed(() => {
  const mobileImg = props?.mobile_background?.[0]?.public_id;
  const desktopImg = props?.background?.[0]?.public_id;
  return deviceStore.isMobile && mobileImg ? mobileImg : desktopImg;
});
const backgroundDimensions = computed(() => {
  const dimensions: { width?: number; height?: number } = { width: undefined, height: undefined };
  if (isSplashPageStyle?.value) {
    dimensions.width = deviceStore.isMobile ? 430 : deviceStore.isTablet ? 1024 : 1376;
    dimensions.height = undefined;
    return dimensions;
  }
  if (props.isSecondaryBanner) {
    dimensions.width = deviceStore.isMobile ? 430 : deviceStore.isTablet ? 1024 : 576;
    dimensions.height = deviceStore.isMobile ? 210 : deviceStore.isTablet ? 500 : 232;
    return dimensions;
  }
  if (props.isPrimaryBanner) {
    dimensions.width = deviceStore.isMobile ? 430 : deviceStore.isTablet ? 1024 : 768;
    dimensions.height = deviceStore.isMobile ? 480 : deviceStore.isTablet ? 819 : 480;
    return dimensions;
  }
  dimensions.width = deviceStore.isMobile ? 430 : deviceStore.isTablet ? 1024 : 1376;
  dimensions.height = deviceStore.isMobile ? 210 : deviceStore.isTablet ? 500 : 232;
  if (props.banner_height === 'short') {
    dimensions.height = deviceStore.isMobile ? 320 : deviceStore.isTablet ? 500 : 320;
  }
  if (props.banner_height === 'tall' && !deviceStore.isDesktop) {
    dimensions.height = 932;
  }
  return dimensions;
});

const componentStyle = computed(() => {
  // @todo recheck device widths later
  const backgroundUrl = cloudinary.generateImageUrl(backgroundImage.value, {
    width: backgroundDimensions.value.width,
    height: backgroundDimensions.value.height,
  });
  return {
    ...(props.background_color?.color ? { backgroundColor: props.background_color?.color } : {}),
    ...(backgroundUrl && !isVideo.value ? { backgroundImage: `url(${backgroundUrl})` } : {}),
    ...(props.show_image_overlay ? { backgroundColor: `rgba(0, 0, 0, 0.4)` } : {}),
  };
});

const bannerTextColorStyle = computed<string | undefined>(() => {
  const color = props.background_color?.color;
  return color ? `color: #000000` : undefined;
});

const hasBackground = computed<boolean>(() => !!props.background?.[0]?.public_id);

const isSplashPageStyle = computed<boolean>(() => {
  return route?.fullPath?.includes('/sp/') && hasBackground?.value;
});
const isVideo = computed<boolean>(() => props.background?.[0]?.resource_type === 'video');
const secondaryBannerAltImg = computed<string>(() => {
  const bg = props.background as AssetCloudinary[];
  return bg?.[0]?.public_id && props.secondary_banner_alt_style && props.isSecondaryBanner ? bg?.[0]?.public_id : '';
});
// START - order here important
const hasMultipleButtons = computed<boolean>(() => props.buttons && props.buttons.length > 1);
const internalLink = computed<string | undefined>(() => {
  if (hasMultipleButtons?.value) return undefined;
  return getInternalLink(0);
});
const href = computed<string | undefined>(() => {
  if (hasMultipleButtons?.value) return undefined;
  return getExternalLink(0);
});
// END - order here important

const align = computed<string>(() => props.content_layout?.horizontal_alignment);
const bannerLayout = computed<string>(
  () => `layout--${props.content_layout?.horizontal_alignment}-${props.content_layout?.vertical_alignment}`
);
// const resourceTitle = computed<string | undefined>(() => useImgAlt(props.background?.[0]?.name));
const videoPoster = computed<string | undefined>(() => {
  return isVideo.value && props.background?.[0]?.public_id
    ? cloudinary.generateVideoUrl(`${props.background?.[0]?.public_id}.jpg`, { ...imageOptions, thumbnail: true })
    : '';
});
const imageOptions = computed(() => {
  const dimensions: { width?: number; height?: number } = { width: undefined, height: undefined };
  dimensions.width = deviceStore.isMobile ? 480 : deviceStore.isTablet ? 1024 : 800;
  dimensions.height = deviceStore.isMobile ? 485 : deviceStore.isTablet ? 576 : 450;
  return dimensions;
});
function getInternalLink(i: number) {
  const url = extractInternalLink(props?.buttons?.[i]?.internal_linkConnection?.edges?.[0]) || undefined;
  const localizedUrl = url ? localePath(url) : url;
  return localizedUrl;
}
function getExternalLink(i: number) {
  return props?.buttons?.[i]?.external_link?.href;
}
function handleClick(e: any) {
  attentive(e, props.buttons?.[0]);
  const link = internalLink.value ? internalLink.value : href.value ? href.value : null;
  router.push(link);
}
</script>

<style scoped>
.banner {
  background-blend-mode: overlay;
}
.banner.splash-banner,
.banner.splash-banner .content {
  color: var(--splash-theme-color);
}
</style>

<style lang="scss" scoped>
.banner {
  $default-height: #{local-functions.rem-calc(480)};

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  box-sizing: border-box;
  height: auto;
  // min-height: $default-height;
  width: 100%;
  padding: #{local-functions.rem-calc(104 96)};
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  // &.splash-banner {
  //   min-height: 44rem;
  //   @include local-mixins.tablet {
  //     min-height: 40rem;
  //   }
  //   @include local-mixins.mobile {
  //     min-height: 60rem;
  //   }
  //   @include local-mixins.large-mobile {
  //     min-height: 45rem;
  //   }
  //   @include local-mixins.small-mobile {
  //     min-height: 37rem;
  //     background-size: contain;
  //   }
  // }
  &.banner-height {
    &--auto {
      min-height: auto;
    }
    &--short {
      min-height: #{local-functions.rem-calc(320)};
    }
    &--medium {
      min-height: #{local-functions.rem-calc(480)};
    }
    &--tall {
      min-height: #{local-functions.rem-calc(640)};
    }
  }
  &.secondary-banner-alt-banner {
    background-image: none;
    background-color: #f4f4f4;
    padding: 1rem 0 1rem 1.5rem !important;
    .content {
      margin-right: 1rem;
    }
  }
  &.video {
    padding: 0;
    position: relative;
    height: $default-height;
    .container {
      width: 100%;
      position: absolute;
      z-index: 1;
      padding: #{local-functions.rem-calc(0 96)};
      height: calc(100% - #{local-functions.rem-calc(104)});
      &.center {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .video-container {
      display: flex;
      width: 100%;
    }
    video {
      height: $default-height;
      width: 100%;
      position: static;
      transform: none;
      left: 0;
      object-fit: cover;
      -o-object-fit: cover;
      object-position: center;
    }

    @include local-mixins.tablet_and_mobile {
      $mob-height: #{local-functions.rem-calc(385px)};

      padding: 0;
      height: auto;

      .container {
        padding: 0 1rem;
        max-height: calc($mob-height - 2rem);
      }
      video {
        height: auto;
        min-height: 30rem;
        position: relative;
        transform: translateX(-50%);
        left: 50%;
      }
    }
  }

  &.left {
    justify-content: left;

    .separator {
      margin: 0 auto 1rem 0;
    }
  }

  &.center {
    justify-content: center;

    .separator {
      margin: 0 auto 1rem;
    }

    .btn {
      margin: 0 auto;
      width: auto;
    }
    :deep(.button-container) {
      margin-left: auto;
      margin-right: auto;
      width: 100%;
    }
  }

  &.right {
    justify-content: right;

    .separator {
      margin: 0 auto 1rem 0;
    }
  }
  &.layout {
    &--left-top {
      justify-content: left;
      align-items: flex-start;
    }
    &--left-center {
      justify-content: left;
      align-items: center;
    }
    &--left-bottom {
      justify-content: left;
      align-items: flex-end;
    }
    &--center-top {
      justify-content: center;
      align-items: flex-start;
    }
    &--center-center {
      justify-content: center;
      align-items: center;
    }
    &--center-bottom {
      justify-content: center;
      align-items: flex-end;
    }
    &--right-top {
      justify-content: right;
      align-items: flex-start;
    }
    &--right-center {
      justify-content: right;
      align-items: center;
    }
    &--right-bottom {
      justify-content: right;
      align-items: flex-end;
    }
  }
  &.use-banner-overlay {
    .video-container {
      &::before {
        content: '';
        position: absolute;
        background: rgb(0 0 0 / 40%);
        z-index: 1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }
  }
  .secondary-banner-alt {
    .secondary-banner-alt-img {
      border-radius: 4px;
      height: 105px;
      width: 105px;
      margin-left: auto;
      @include local-mixins.desktop {
        height: 190px;
        width: 190px;
      }
    }
  }
  @include local-mixins.desktop {
    &.left,
    &.right {
      .container {
        width: 50%;
      }
    }
  }
  &.short-height-style {
    padding-top: 0;
    padding-bottom: 0;
    min-height: auto;
    @include local-mixins.desktop {
      padding: 0 0.5rem;
    }
    .container {
      @include local-mixins.desktop {
        width: 100%;
      }
      .content {
        max-width: auto;
        padding: 0;
        @include local-mixins.desktop_and_tablet {
          display: flex;
          width: 100%;
          :deep(> *) {
            width: 50%;
            .btn {
              max-width: 300px;
            }
          }
        }
        &.left {
          padding-top: 0;
          padding-bottom: 0;
        }
      }
    }
  }
}
.container.center,
.content.center {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.container {
  height: 100%;
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  text-transform: capitalize;
  text-align: left;
  color: $color-neutral-white;
  font-weight: 500;
  .separator {
    width: #{local-functions.rem-calc(120px)};
    margin-block-start: unset;
    margin-block-end: unset;
    margin-inline-end: unset;
    margin-inline-start: unset;
  }

  .btn {
    width: fit-content;
  }

  .cta {
    margin-top: 1rem;
  }
}
.inset-logo {
  margin: 0 auto;
  max-width: 120px;
}
@include local-mixins.tablet_and_mobile {
  .banner {
    height: auto;
    min-height: 440px;
    padding: 2rem 1rem;
    .container {
      .separator {
        width: #{local-functions.rem-calc(88px)};
      }
    }
  }
}
</style>
